.main-navbar {
  z-index: 1000; /* Ensure it is on top of other elements */
}

.secondary-navbar {
  position: fixed;
  top: 54px; /* Adjust based on the height of the main navbar */
  width: 100%;
  z-index: 999;
}
