.custom-carousel {
  max-height: 400px;
  overflow: hidden;
}

.custom-carousel .carousel-item {
  max-height: 400px;
}

.custom-carousel-img {
  object-fit: cover;
  cursor: pointer;
  max-height: 400px;
}

.custom-carousel .carousel-caption {
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  padding: 10px; /* Reduced padding for better fit on small screens */
  border-radius: 8px; /* Rounds the corners of the caption background */
  bottom: 20px; /* Adjusted bottom position */
  left: 10px; /* Adjusted left position */
  right: 10px; /* Adjusted right position */
  text-align: left; /* Align text to the left for better readability */
}

.custom-carousel .carousel-caption h3 {
  color: #fff; /* White color for the title text */
  margin-bottom: 5px; /* Reduced margin for better fit */
}

.custom-carousel .carousel-caption p {
  color: #ddd; /* Light gray color for the paragraph text */
  margin-bottom: 0;
  font-size: 0.9rem; /* Slightly smaller text size for better fit */
}

.text-end {
  text-align: right;
}

.btn-primary {
  margin-top: .1rem;
}

/* Responsive adjustments */
@media (max-width: 576px) {
  .custom-carousel .carousel-caption {
    padding: 8px; /* Reduced padding for small screens */
    font-size: 0.9rem; /* Adjust font size for smaller screens */
  }
}
