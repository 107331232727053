.testimonial-card {
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
  padding: 25px; 
  background-color: #fff; 
  max-width: 800px; /* Increased the width of the card */
}

.testimonial-text {
  font-size: 1.3rem;
  color: #444;
}

.testimonial-author {
  font-size: 1rem;
  color: #777;
  text-align: right;
  margin-top: 1.5rem;
}

.carousel-item {
  text-align: center;
}

.testimonial-carousel .carousel-control-prev,
.testimonial-carousel .carousel-control-next {
  width: 5%; /* Set width for control buttons to be outside the card */
}

.carousel-control-prev,
.carousel-control-next {
  filter: invert(1); /* Invert color for contrast */
}

.review-button {
  background-color: #007bff;
  border: none;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  border-radius: 5px;
  transition: background-color 0.3s ease;
}

.review-button:hover {
  background-color: #0056b3;
}




/* TEST 1 */
.review-link {
  font-size: 1rem;
  font-weight: bold;
  color: #007bff;
  text-decoration: none;
  transition: color 0.3s ease;
}

.review-link:hover {
  color: #0056b3;
}

.google-icon {
  margin-right: 5px;
  vertical-align: middle;
}
