/* ProjectsPage.css */
.project-card {
  transition: transform 0.2s ease, box-shadow 0.2s ease;
  cursor: pointer;
  border: none;
  border-radius: 10px;
  overflow: hidden;
}

.project-card:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.project-card .card-img-top {
  height: 200px;
  object-fit: cover; /* Maintains aspect ratio, cropping if necessary */
  width: 100%; /* Ensures image covers the full width of the card */
}


.project-card .card-body {
  padding: 1.5rem;
}

.project-card .card-title {
  font-weight: bold;
  margin-bottom: 0.75rem;
}

.project-card .card-text {
  color: #6c757d;
}

.modal-img {
  height: auto;
  max-height: 70vh; /* Adjust based on your preference */
  object-fit: contain; /* Maintains aspect ratio without cropping */
  width: 100%; /* Ensures image covers the full width of the modal */
}

