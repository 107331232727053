.service-detail-wrapper {
  display: flex;
  flex-direction: column; /* Stack items vertically on small screens */
  padding: 1rem;
  gap: 1rem;
  align-items: center; /* Center content horizontally */
}

.service-nav {
  border-right: 1px solid #e0e0e0;
  padding-right: 1rem;
  background-color: #f8f9fa;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  width: 100%; /* Full width on smaller screens */
  max-width: 300px; /* Limit the width on larger screens */
}

.service-nav h3 {
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.service-nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.service-nav li {
  margin-bottom: 0.5rem;
}

.service-nav-link {
  display: block;
  text-decoration: none;
  color: #333;
  font-size: 1rem;
  padding: 0.5rem;
  border-radius: 4px;
  transition: background-color 0.3s, color 0.3s;
}

.service-nav-link:hover,
.service-nav-link.active {
  background-color: #007bff;
  color: #fff;
}

.service-detail-content {
  flex: 1; /* Takes full width on mobile and adjusts on larger screens */
  text-align: center; /* Center text content */
}

.service-detail-content h1 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.service-detail-content p {
  font-size: 1.125rem;
  margin-bottom: 1.5rem;
}

.service-image {
  width: 100%;
  height: 350px; /* Increase height for larger image */
  object-fit: cover;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  max-width: 600px; /* Limit the max width */
}

.service-dropdown {
  width: 100%;
  margin-bottom: 1rem;
}

/* Media queries for larger screens */
@media (min-width: 768px) {
  .service-detail-wrapper {
    flex-direction: row; /* Switch to horizontal layout on larger screens */
  }

  .service-dropdown {
    display: none; /* Hide the dropdown on larger screens */
  }

  .service-nav {
    border-right: 1px solid #e0e0e0;
    border-bottom: none;
    padding-right: 1rem;
  }

  .service-detail-content {
    flex: 3;
    text-align: center; /* Keep content centered */
  }

  .service-detail-content h1 {
    font-size: 2.75rem; /* Slightly larger font on larger screens */
  }

  .service-detail-content p {
    font-size: 1.25rem;
  }

  .service-image {
    height: 400px; /* Increase height on larger screens */
    max-width: 800px; /* Limit the max width on larger screens */
  }
}
