/* QuoteButton.css */
.modal-title {
  font-size: 1.5rem;
  font-weight: bold;
}

.contact-info {
  font-size: 1.2rem;
  margin: 10px 0;
}

.contact-info a {
  color: #007bff;
  text-decoration: none;
}

.contact-info a:hover {
  text-decoration: underline;
}

.modal-footer {
  border-top: none; /* Remove border for a cleaner look */
}
