/* OurServicesStyle.css */

.service-card {
  transition: transform 0.15s ease, box-shadow 0.1s ease;
  cursor: pointer;
}

.service-card:hover {
  transform: scale(1.01);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.service-card .card-body {
  padding: 1.5rem; /* Adjust padding for better spacing */
}

.service-card .card-title {
  font-weight: bold;
}
