/* Jumbotron.css */
.jumbotron-custom {
  position: relative;
  background-image: url('../images/Brothers-carpentry-photos/deck3.jpg');
  background-size: cover;
  background-position: center;
  min-height: 80vh;
  padding: 5rem 1rem;
  color: #fff;
  text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}

.jumbotron-custom::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Overlay with opacity */
  z-index: 0;
}

.jumbotron-custom h1,
.jumbotron-custom p,
.jumbotron-custom button {
  position: relative;
  z-index: 1; /* Ensure content is above the overlay */
}
